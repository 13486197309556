import Welcome from "../../Page/Welcome";
import Home from "../../Page/Home";
import Application from "../../Page/Application";
import StudyAbroad from "../../Page/StudyAbroad";
import Support from "../../Page/Support";
import QA from "../../Page/QA";
import Login from "../../Page/Login";
import Inquiry from "../../Page/Inquiry";
import Present from "../../Page/Present";
import Register from "../../Page/Register";
import ApplicationForm from "../../Page/ApplicationForm";
import Lessons from "../../Page/Lessons";
import Payment from "../../Componets/Payment";
import Success from "../../Page/Success";
import Failure from "../../Page/Failure";
import { useNavigate } from "react-router-dom";
import Menu from "../../Componets/Menu";
import { UserContext } from "../../UserContext";
import { useContext, useEffect } from "react";
import { Routes,Route, } from "react-router-dom";


const PageManagement = () => {
  const navigate = useNavigate();
  const { context, setContent } = useContext(UserContext);
  const loginPages = ["/レッスン", "/申請者", "/申請者"];


    if(loginPages.includes(decodeURI(window.location.pathname)) && context.auth == null){
        navigate(`/ホーム`);
      }



  return (
    <div className="h-100">
        <Menu />
        <div className="h-100" style={{zIndex: '25'}}>
        <div className="menu-gap" />
        <Routes>
          
          <Route exact path="/" element={<Welcome />}/>
          <Route path={`/${encodeURI('ホーム')}`} element={<Home />} />
          <Route path={`/${encodeURI('練習生留学')}`} element={<StudyAbroad />} />
          <Route path={`/${encodeURI('お申し込み')}`} element={<Application />} />
          <Route path={`/${encodeURI('Q&A')}`} element={<QA />} />
          <Route path={`/${encodeURI('サポーター')}`} element={<Support />} />
          <Route path={`/${encodeURI('お問い合わせ')}`} element={<Inquiry />} />
          <Route path={`/login`} element={<Login />} />
          <Route path={`/${encodeURI('register')}`} element={<Register />} />
          <Route path={`/${encodeURI('申請者')}`} element={<ApplicationForm />} />
          <Route path={`/${encodeURI('レッスン')}`} element={<Lessons />} />
          <Route path={`/${encodeURI('payment')}`} element={<Payment />} />
          <Route path={`/${encodeURI('success')}`} element={<Success />} />
          <Route path={`/${encodeURI('failure')}`} element={<Failure />} />
          <Route element={<Welcome />} />
        </Routes>
        </div>
    </div>
  );
}

export default PageManagement;
