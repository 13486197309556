import VISA from "../../assets/img/visa.jpg";
import MASTERCARD from "../../assets/img/mastercard.png";
import DINER from "../../assets/img/diners-club-logo.jpg";
import DISCOVER from "../../assets/img/discover.png";
import JCB from "../../assets/img/JCB.png";
import GPAY from "../../assets/img/gpay.jpg";

import JPBANK from "../../assets/img/japanpostal.jpeg";
import MIZUHO from "../../assets/img/mizuho.jpg"
import MUFG from "../../assets/img/mufg.jpg"
import SMBC from "../../assets/img/smbc.png"
import SHINSEI from "../../assets/img/shinsei.png"
import RESONA from "../../assets/img/resona.jpeg"

import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../UserContext";
import PaymentModal from "../PaymentModal";


const Payment = () => {
    const navigate = useNavigate();
    const { context, setContent } = useContext(UserContext);
    const [bankInfo, setBankInfo ] = useState(false);

    const bankPay = () => {
    }

    const getOrderInfo = () => {
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/payments/order/${context.user.id}`, {
          // auth: {
          //   username: context.auth.username,
          //   password: context.auth.password,
          // },
        })
        .then(function (response) {
            setContent(prevContext => ({...prevContext, order: response.data[0]}))
        })
        .catch(function (error) {
           navigate(`/${encodeURI('login')}`)
          console.log(error);
        });
    }

    useEffect(()=>{
        getOrderInfo(context.user.id)
        if(!context.auth){
            navigate(`/login`);
          }
    },[])


    return (
       context.order?.id ? ( <div className="content-p flex-grid-container mx-auto row vh-70">
            {bankInfo && <div className="backdrop h-100" style={{position: "absolute", top: "0", left: "0"}}/> }
           {bankInfo && (<PaymentModal closeModal={()=> setBankInfo(false)}/>)
            }
            <form className="content-p col ml-auto m-mr-auto" id="stripeForm" method="post" action={`${process.env.REACT_APP_BASE_URL}/payments/start`}>
                <table className="mt-auto bg-white rounded p-10">
                    <tr className="w-100">
                    <td className="payment-image-container">
                            <img src={VISA} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={MASTERCARD} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img src={DINER} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "cover"}} src={DISCOVER} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img style={{objectFit: "fit"}} src={JCB} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "fit", width: "100%"}} src={GPAY} />
                        </td>
                    </tr>
                </table>
                <input type="hidden" value={context.order.userId} name="userId"/>
                <input type="hidden" value={context.order.id} name="orderId"/>
                <br/>
                <div className="btn mx-auto mb-auto" onClick={()=>document.getElementById('stripeForm').submit()}>支払い</div>
            </form>
            <form className="content-p col m-ml-auto mr-auto">

            <table className="mt-auto bg-white rounded p-10">
                    <tr className="w-100">
                      <td className="payment-image-container">
                            <img src={JPBANK} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={MIZUHO} className="payment-image"/>
                        </td>
                    </tr>
                    <tr>
                    <td className="payment-image-container">
                            <img style={{objectFit: "cover"}} src={MUFG} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img style={{objectFit: "fit", width: "100%"}} src={SMBC} />
                        </td>
                    </tr>
                    <tr>
                        <td className="payment-image-container">
                            <img src={SHINSEI} className="payment-image"/>
                        </td>
                        <td className="payment-image-container">
                            <img src={RESONA} className="payment-image"/>
                        </td>
                    </tr>
                </table>
                <input type="hidden" value={context.order.userId} name="userId"/>
                <input type="hidden" value={context.order.id} name="orderId"/>
                <br/>
                <div className="btn mx-auto mb-auto" onClick={()=>setBankInfo(!bankInfo)}>支払い</div>
            </form>     
            </div>
                ) : (<div>Loading</div>)
    )
}

export default Payment;