import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

const Register = () => {
    const navigate = useNavigate();
    // const [data, setData] = useState({"username": "gregory.krynski@gmail.com", "password": "1234567", "rePassword": "1234567"});

    const [data, setData] = useState({"username": "", "password": "", "rePassword": ""});
    const updateState = (e) => {
      setData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    };
  
    const createUser = (e) => {
    e.preventDefault();
    if(data.username !== null && data.username !== "" && data.username.length >= 4){
        if(data.password !== null && data.password !== "" && data.password.length >= 6){
            if(data.password ===  data.rePassword){
                    axios.post(`${process.env.REACT_APP_BASE_URL}/users`, {"username": data.username, "password": data.password})
                    .then(function (response) {
                        navigate(`/login`);
                        console.log(response);
                    })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
    }
  

    return (
        <div className="vh-100 col content-p"
        style={{
          display: "flex",
          flexDirection: "column"}}>
              <div className="container-400px col d-flex mr-auto ml-auto">
            <form>
            <h3>Register Account</h3>
            <input className="input-text w-100 wm-600px " type="email" placeholder="email"
            id="username"
            value={data.username}
            onChange={updateState} required/>
            <input className="input-text w-100 wm-600px " type="password" placeholder="password"
            id="password"
            value={data.password}
            onChange={updateState} required/>
            <input className="input-text w-100 wm-600px " type="password" placeholder="retype password" 
            id="rePassword"
            value={data.rePassword}
            onChange={updateState} required/>
            <div className="row">
            <button className="input-text w-100 wm-600px btn mr-auto ml-auto" type="submit" onClick={createUser}>Register</button>
            </div>
            </form>
            </div>
        </div>
    )
}

export default Register;