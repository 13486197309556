import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
      navigate(`/${encodeURI('ホーム')}`);
  }, [])

  return (
    <div className="flex-grid-container mx-auto vh-100 content-p">
        <div className="center-div">
            <h3>
                Payment Failure
            </h3>
        </div>
    </div>
  );
};

export default Success;
