import "./App.css";

import { UserContext } from "./UserContext";
import { useState, useEffect } from "react";
import { BrowserRouter as Router  } from "react-router-dom";
import PageManagement from "./Componets/PageManagement";

function App() {
  const [context, setContent] = useState({auth: null, order: {id: null, userId: null}});

  useEffect(() => {
    if (localStorage.getItem("uas") !== null) {
      setContent(JSON.parse(localStorage.getItem("uas")));

    }
  },[]);

  useEffect(() => {
    if (context.auth !== null) {
      localStorage.setItem("uas", JSON.stringify(context));
    }
  },[context]);

  return (
    <div className="h-body">
      <UserContext.Provider value={{context, setContent}}>
      <Router>
        <PageManagement/>
      </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
