import VISA from "../../assets/img/visa.jpg";
import MASTERCARD from "../../assets/img/mastercard.png";
import DINER from "../../assets/img/diners-club-logo.jpg";
import DISCOVER from "../../assets/img/discover.png";
import JCB from "../../assets/img/JCB.png";
import GPAY from "../../assets/img/gpay.jpg";

import JPBANK from "../../assets/img/japanpostal.jpeg";
import MIZUHO from "../../assets/img/mizuho.jpg"
import MUFG from "../../assets/img/mufg.jpg"
import SMBC from "../../assets/img/smbc.png"
import SHINSEI from "../../assets/img/shinsei.png"
import RESONA from "../../assets/img/resona.jpeg"

import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../UserContext";
import { useClickOutside } from 'react-click-outside-hook'


const PaymentModal = (props) => {
    const {closeModal} = props;
    const navigate = useNavigate();
    const { context, setContent } = useContext(UserContext);
    const [bankInfo, setBankInfo ] = useState(false);
    const [elementRef, hasClickedOutside] = useClickOutside();

    const bankPay = () => {
    }


    const getOrderInfo = () => {
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/payments/order/${context.user.id}`, {
          // auth: {
          //   username: context.auth.username,
          //   password: context.auth.password,
          // },
        })
        .then(function (response) {
            setContent(prevContext => ({...prevContext, order: response.data[0]}))
        })
        .catch(function (error) {
           navigate(`/${encodeURI('login')}`)
          console.log(error);
        });
    }

    useEffect(()=>{
        if(hasClickedOutside === true){
           closeModal();
        }
    },[hasClickedOutside])


    return (
             <div className="abs-center-div rounded bg-white" style={{padding: "10px",width: "320px", height: "70vh"}} ref={elementRef}>
             <div className="ml-auto w-content" style={{fontSize: "21px", color: "black"}} onClick={()=>closeModal()}>X</div>
             <p style={{color: "black"}}> 
                <h4>
                銀行振込でお支払い希望の場合
                <br/>
                <br/>
                お振込先
                </h4>
                <table className="payment-table">
                    <tr className="payment-table-row">
                        <th className="payment-table-cell">アカウント名</th>
                        <td className="payment-table-cell">DME-WORLD合同会社</td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="">銀行名</th>
                        <td>
                            <div className="payment-table-cell">
                            三菱UFJ銀行
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="">支店名</th>
                        <td>
                            <div className="payment-table-cell">
                                渋谷中央支店
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th>支店番号</th>
                        <td>
                            <div className="payment-table-cell">
                                345
                            </div>
                          </td>
                    </tr>
                    <tr className="payment-table-row">
                        <th className="payment-table-cell">口座番号</th>
                        <td>
                            <div className="payment-table-cell">
                                0782409
                            </div>
                          </td>
                    </tr>
                </table>
             </p>
            </div>
    )
}

export default PaymentModal;