import FAQBANNER from "../../assets/img/q-and-a.jpg";

const QA = () => {
  const QAproject = [
    {
      Question: "歌もダンスも初心者ですが参加できますか？",
      Answer:
        "はい、参加可能です。誰でも最初は未経験ですので今現在の実力ではなく、大事なのは６ヶ月後のレッスン終了時までにどれだけ練習を重ね、実力を付けられるかです。大きくレベルアップが出来ていれば今は初心者でも来年のレベルチェックで合格できる可能性もありますのでぜひ頑張って下さい。",
    },
    {
      Question:
        "受講終了後のレベルチェックで成績優秀者に選ばれるのは何名ですか？ 練習生に必ずなれますか？",
      Answer:
        "はい、参加可能です。誰でも最初は未経験ですので今現在の実力ではなく、大事なのは６ヶ月後のレッスン終了時までにどれだけ練習を重ね、実力を付けられるかです。大きくレベルアップが出来ていれば今は初心者でも来年のレベルチェックで合格できる可能性もありますのでぜひ頑張って下さい。",
    },
    {
      Question: "6ヶ月のコースですが途中で辞めることはできますか？",
      Answer:
        "可能です。但しこちらは6ヶ月で一つのコースになっているものへの申し込みとなり、受講者の都合でトレーニングを途中で辞めた場合も6ヶ月分の月謝が発生し、返金不可となりますのでご了承ください。その代わり、当主催の開催する他企画へ参加費を充当可能です。受講期間内に他のオーディションに合格し事務所所属が決まった場合など返金ができる特例もありますのでその際はご相談ください。万が一主催者の都合で開催されなかった場合は全額返金となります。",
    },
    {
      Question: "忙しくて毎週レッスンを受ける時間が取れなくても大丈夫ですか？",
      Answer:
        "コロナの影響で全て動画によるレッスンになるため、いつ練習をするかは全てご自身の自由となっております。空いた時間でレッスン動画を見て学んでください。",
    },
    {
      Question:
        "動画でのレッスンでもわからないことがあった場合は質問やアドバイスはもらえますか？",
      Answer:
        "はい、質問もアドバイスも可能です。専用SNSページよりメッセージをお願いします。全員に返信します。",
    },
    {
      Question:
        "ダンサー志望ですがダンスのレッスンのみを受けることはできますか？",
      Answer:
        "歌とダンスのレッスンはセットになっているためどちらか一つの受講にはなりませんが、ダンサー志望の場合はレベルチェックでダンスの成績を重視します。歌のレッスンではリズム感、正しい呼吸法などダンスでも活かせることが学べます。",
    },
    {
      Question:
        "レベルチェックでの採点結果は不合格だった場合も教えてもらえますか？",
      Answer: "希望者にはお知らせ可能です。",
    },
    {
      Question: "レベルチェックで良い成績を残せなかった場合はどうなりますか？",
      Answer:
        "今回は残念ながら韓国へご招待はされませんが、また次回このプロジェクト開催時に今回最後にもらえる全員プレゼントのクーポンを使い、お得にまた夢への挑戦ができます。",
    },
    {
      Question: "人数限定とはだいたい何人くらいですか？",
      Answer:
        "全国から約100人前後です。お申し込みの状況により追加される場合もあります。",
    },
  ];

  const QAapplication = [
  {
    Question: "どこから申し込めばいいですか？",
    Answer:
      "こちらの公式HPのお申し込みフォームから必要事項を記入し送信して下さい。",
  },
  {
    Question: "現在9歳で今年10歳になるのですが申し込みできますか？",
    Answer: "受講終了時2022年3月末までに10歳になっていれば可能です。",
  },
  {
    Question: "年齢に上限はありますか？",
    Answer: "ありません。夢を諦めきれない大人の方もぜひ挑戦お待ちしてます。",
  },
];

const QAkorea = [
  {
    Question: "韓国での生活で自分で費用を払うものはありますか？",
    Answer: "現地での食費、交通費、個人的な買い物、海外旅行保険（希望の場合）は自己負担でお願いします。",
  },
  {
    Question: "韓国語ができませんが韓国へ行けますか？",
    Answer: "行けます。ただ言葉がわからないと現地で困ることもたくさん出てきますので日本にいる間になるべくたくさん勉強してから渡韓してください。",
  },
  {
    Question: "もし韓国行きが決まってもまだコロナが心配なので翌年以降に渡韓でもいいですか？",
    Answer: "はい、もちろんです。今すぐに渡韓時期を決める必要はなく、コロナの状況を見ながらご都合の良い時期に決めて頂き全く問題ありません。",
  },
  {
    Question: "どこの事務所の練習生になれますか？",
    Answer: "基本的に大手芸能事務所全てに可能性があります。",
  },
  {
    Question: "韓国に行けば必ずデビューできますか？",
    Answer: "デビューの保証は他の練習生と同じでありません。ただ練習生にならないことにはデビューのチャンスは無いので練習生になってあなたの努力で夢をつかんでください。",
  },
]

  return (
    <div>
        <div className="big-content content-p">
        <h1 className="faq-h1 w-content mx-auto">Q&amp;A</h1>
        </div>
        <div className="big-content content-p-l content-p-r">
          <div className="mx-auto w-content row">
        <img
          className="w-100 h-100 mx-auto"
          src={FAQBANNER}
        />
        </div>
      </div>
    <div className="big-content d-w-75 content-p">
      <h2>よくある質問</h2>
      <h3>プロジェクトについて</h3>
      {QAproject.map((data) => {
        return (
          <div>
            <p>Q. {data.Question}</p>
            <p>A. {data.Answer}</p>
          </div>
        );
      })}
      <h3>プロジェクトについて</h3>
      {QAapplication.map((data) => {
        return (
          <div>
            <p>Q. {data.Question}</p>
            <p>A. {data.Answer}</p>
          </div>
        );
      })}
      <h3>韓国での生活について</h3>
      {QAkorea.map((data) => {
        return (
          <div>
            <p>Q. {data.Question}</p>
            <p>A. {data.Answer}</p>
          </div>
        );
      })}
    </div>
    </div>
  );
};

export default QA;
