import BANNER from "../../assets/img/banner-1.jpg";
import APPLICATION from "../../assets/img/Application.jpg";
import INTHESPOTLIGHT from "../../assets/img/In-the-spotlight.jpg";
import { useNavigate } from "react-router-dom";

const Application = () => {
  const navigate = useNavigate();

  const setTopPage = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <div className="w-100">
      <div className="big-flex-grid-container">
        <div className="application-item1">
          <img
            className="w-100 h-100"
            src={BANNER}
          />
        </div>
        <div className="application-item2 content-p">
          <h3 className="normal fs-24px">
            １、下記お申し込みメールに名前/メールアドレスを記入し送信
            24時間以内にご案内メールが全員に届きます
          </h3>
          <h3 className="normal fs-24px">
            ２、ご案内メールに従い、プロフィール提出と初回月謝(または一括)の銀行振込
          </h3>
          <h3 className="normal fs-24px">３、お申し込み完了メールが届き１０月より受講開始となります</h3>
          <h3 className="normal fs-24px">会員専用SNSアカウントをフォローして動画の配信をお待ち下さい</h3>
        </div>
      </div>
      <div className="content col mx-auto">
        <div className="background-application-image"
          style={{
            display: "flex", 
            flexDirection: "column",
            backgroundImage:
              `url(${APPLICATION})`,
          }}
        >
          <div className="backdrop">
          <div className="my-auto content-p-l"
            style={{
              width: "300px",
            }}
          >
            <h2 className="w-content">お申し込み</h2>
            <h3 className="w-content">下記のお申し込みメールから</h3>
            <h3 className="text-left">
              １ お名前
              <br />
              ２ メールアドレス
              <br />
              をお送りください
            </h3>
            <div style={{cursor: "pointer", backgroundColor: "white", color: "black", fontSize: "1.5rem", width: "190px", height: "50px", borderRadius: "5px"}} onClick={()=> {
                setTopPage();
                navigate(`/申請者`);
              }
                }>
              <div className="center-div w-content">
              お申し込みメール
              </div>
            </div>
          </div>
            </div>
        </div>
        <div className="spacer"/>
        <div className="spacer"/>
        <div className="background-image-3"
          style={{
            backgroundImage:
              `URL(${INTHESPOTLIGHT})`,
          }}
        >
             <div className="backdrop">
          <div  className="center-div"
            style={{
              width: "400px",
            }}
          >
            <h3>スポットライトを浴び</h3>
            <h3>沢山のファンがあなたの名前を呼び</h3>
            <h3>輝くために今、挑戦しよう！</h3>
            <br />
            <h3>あなたの夢を叶えられるのは</h3>
            <h3>あなたしかいない。</h3>

            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
