import CITY from "../../assets/img/city.jpg";

const Inquiry = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#000",

          backgroundImage: `url(${CITY})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="backdrop">
        <div
          style={{
            textAlign: "center",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <h2 >お問い合わせ</h2>
          <h3 className="bold">Q&Aよくある質問に掲載がない質問等</h3>
          <h3 className="bold">については事務局までメールで</h3>
          <h3 className="bold">お問い合わせ下さい</h3>
          <h3 className="bold">メール送信先 info@dmeworld.net</h3>
          <br/>
          <div>
            <p >
            主催: DME WORLD
            <br />
            本社: 東京都港区南青山2-2-15-942
            <br />
            六本木オフィス: 東京都港区六本木6-12-4
            <br />
            六本木ヒルズレジデンス
            <br />
            TEL: 03-6869-1593 FAX: 03-6893-3931
            <br />
            担当: K-POP STAR PROJECT事務局
            <br />
            新人開発部 綾瀬由奈
            </p>
          </div>
          <br/>
          <br/>
            <a className="mail-to" href="mailto:kpopstarproject@dmeworld.net" target="_self" rel="noreferrer noopener" draggable="false">
                Mail
            </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
