import {useEffect } from "react";

const AlertBox = (props) => {
    const {msg, close} = props;

    useEffect(() => {
        setTimeout(() => {
            close()
        }, 5000);
      }, [])

    return(
        <div className="alert-box">
            {msg}
        </div>
    )
}

export default AlertBox;