import { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useNavigate } from "react-router-dom";
import { useClickOutside } from 'react-click-outside-hook'

const HamburgerButton = (props) => {
    const navigate = useNavigate();
    const {children} = props;
    const { context, setContent } = useContext(UserContext);
    const [elementRef, hasClickedOutside] = useClickOutside()
    const [display, setDisplay] = useState(false);

const clearSession = () => {
  localStorage.setItem("uas", JSON.stringify({auth: null, order: {id: null, userId: null}}));
  
  setContent({auth: null});
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  navigate("/")
}

useEffect(()=>{
  if(hasClickedOutside === true){
    setDisplay(false);
  }
},[hasClickedOutside])

  return (
    <div className="hamburger-btn-container" ref={elementRef}>
      <div className="hamburger-btn" onClick={()=> setDisplay(!display)}>
        <div className="white-bar"></div>
        <div className="white-bar"></div>
        <div className="white-bar"></div>
      </div>
      <div onClick={()=> setDisplay(!display)} className={`${display ? 'hamburger-display-flex' : 'hamburger-display-none' } `}>
          {children}
      </div>
      <div className="ml-auto col">
        <div className="mt-auto row">
          <a className="Logo my-auto" href="/">K POP STAR PROJECT</a>
          {
            context.auth ? (<a onClick={clearSession} className="login-btn">LOGOUT</a>) : (<a onClick={() => navigate('/login')} className="login-btn">LOGIN</a>)
          }
        </div>
      </div>
    </div>
  );
};

export default HamburgerButton;
