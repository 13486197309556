import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import VOCAL from "../../assets/img/VOCAL.jpg";
import DANCE from "../../assets/img/DANCE.jpg";
import ONLINE from "../../assets/img/ONLINE.jpg";
import BIGCHANCE from "../../assets/img/BIG-CHANCE.jpg";
import KIYO from "../../assets/img/Kiyo.jpg";
import YORITO from "../../assets/img/Yorito.jpg";
import KOTA from "../../assets/img/Kota.jpg";
import TSUBASA from "../../assets/img/Tsubasa.jpg";
import TRANIEE from "../../assets/img/Become-a-trainee-in-korea.jpg";
import HOMEBANNER from "../../assets/img/Home-Banner.jpg";
import REDCARPET from "../../assets/img/REDCARPET.jpg";
import RENAN from "../../assets/img/RENAN.jpg";
import SONGMINJEONG from "../../assets/img/SONGMINJEONG.jpg";

const Home = () => {
  let navigate = useNavigate();
  const specialTraining = [
    {
      title: `DANCE`,
      text: `ダンスレッスン<br/>デビューに必要不可欠な K-POP ダンスを韓国人気アイドル現役バックダンサー、経験豊富なトップダンサー講師から丁寧に教えてもらえる！`,
      image: DANCE,
    },
    {
      title: "VOCAL",
      text: `ヴォーカルレッスン<br/><br/>聞いた人の心に届く素敵な歌声、ヴォーカルテクニックを身につけて大きなステージで堂々と歌えるようになろう！`,
      image: VOCAL,
    },
    {
      title: "ONLINE",
      text: `オンラインレッスン<br/><br/>週１回、メンバー専用ページにてレッスン動画が配信され、空いた時間にいつでもどこでもレッスン動画をチェックできます。忙しい方も大丈夫！`,
      image: ONLINE,
    },
    {
      title: "BIG CHANCE",
      text: `ビッグチャンス<br/><br/>受け入れ人数限定の特別育成プロジェクトの特権として、多数の人が受けている他の公開オーディションより合格率が圧倒的に高い！`,
      image: BIGCHANCE,
    },
  ];

  return (
    <>
    <div>
      <div
        className="vh-100"
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="vh-100"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            textAlign: "center",
            justifyContent: "center",
            backgroundImage:
              `url(${HOMEBANNER})`,
          }}
        >
          <div className="backdrop">
            <div className="container-400px center-div">
              <h2>練習生になり</h2>
              <h2>韓国デビューを目指せ！</h2>
              <p className="text-center bold">
                K-POP STAR PROJECT
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content-p">
        <div className="big-content"
        >
          <div className="flex-grid-container mx-auto">
            <div className="lg-d-content-p">
              <h2>圧倒的な合格率 ！！</h2>
              <h2>夢を叶える最短ルート</h2>
              <p>K-POPアイドルまたはバックダンサーになりたい方必見！</p>
              <p>
                自宅で６ヶ月のオンライン特別育成コースを受けた後、レベルチェックにて成績優秀者になると韓国行きが決定。
              </p>
              <p>
                合格人数に制限なし。 他のオーディションより圧倒的に高い合格率！
              </p>
              <p>
                練習生になること、韓国デビューを目標にしたプロジェクトです。
                合格後の渡韓費用は当プロジェクトが全負担！
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "888px",
          textAlign: "center",
          justifyContent: "center",
          backgroundImage:
            `URL(${REDCARPET})`,
        }}
      >
        <div className="backdrop">
          <div className="center-div">
            <h2>このK-POPスタープロジェクトが</h2>
            <h2>他社より選ばれる理由</h2>
            <p className="sm-p mr-auto ml-auto">
              全国から人数限定で韓国デビューしたい方を受け入れ、
              <br />
              『韓国デビュー』という夢を叶える事に
              <br />
              特化したあなたのための
              <br />
              “夢実現プロジェクト”です。
            </p>
            <p className="sm-p mr-auto ml-auto">
              他社のようにただレッスンを受けて終わりではなく、習ったことを夢の実現へと
              <br />
              活かすことを大切にし、一人一人を
              <br />
              サポートしていきます。
            </p>
          </div>
        </div>
      </div>
      <div className="d-content-p-t d-content-p-r d-content-p-l">
        <div className="big-content content-p">
          <h3 className="bold">ダンス＆ヴォーカル特別育成コース</h3>
          <div className="array-list">
            {specialTraining.map((data) => {
              return (
                <div className="support-item">
                  <div className="array-img-container">
                    <img className="array-img" src={data.image} alt="image" />
                  </div>
                  <h3>{parse(data.title)}</h3>
                  <div>
                    <p>{parse(data.text)}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-content-p">
        <div className="big-content content-p">
          <h2 className="bold">ダンス インストラクター</h2>
          <div className="grid-container w-100">
            <div className="instructor-item1 sm-d-content-p-r">
              <img className="instructor-img" src={KIYO} alt="image" />
            </div>
            <div className="instructor-item3 sm-d-content-p-r">
              <h2>KIYO</h2>
              <p>
                振付アシスタント
                <br />
                BLACKPINK (ジェニー)
                <br />
                iKON/ SEVENTEEN/
                <br />
                三代目J Soul Brothers
                <br />
                EXILE THE SECOND
                <br />
                E-girls/ SKE48/ 関ジャニ∞/ ジャニーズ
                <br />
                WEST/ Nissy/
                <br />
                Da-iCE/ SKY-HI/ lol / ハロプロ/
                <br />
                AAAツアーアシスタント
                <br />
                <br />
                MV出演
                <br />
                安室奈美恵 / 加藤ミリヤ
                <br />
                V6 / XOX / SUPER SONICS
                <br />
                <br />
                TV / LIVE出演
                <br />
                三浦大知バックダンサー紅白歌合戦2018
                <br />
                ナオト・インティライミ ツアーダンサー
                <br />
                乃木坂46 ライブバックダンサー
                <br />
                <br />
                その他、TV、CM、映画等多数出演
              </p>
            </div>
            <div className="instructor-item2 sm-d-content-p-1">
              <img className="instructor-img" src={YORITO} alt="image" />
            </div>
            <div className="instructor-item4 sm-d-content-p-l">
              <h2>YORITO</h2>
              <p>
                ダンス指導
                <br />
                TWICE (ミナ)
                <br />
                <br />
                ツアーダンサー
                <br />
                SHINee 全国ツアー ダンサー
                <br />
                TAEMIN 武道館ツアーダンサー
                <br />
                IU 全国5大都市イベントダンサー
                <br />
                東京ガールズコレクションライブ
                <br />
                <br />
                LIVE 出演
                <br />
                Block.B、大国男児
                <br />
                <br />
                振り付け
                <br />
                100%『Warrior』
                <br />
                <br />
                その他ダンサー、振り付け師、モデルとしてイベント出演等方面で活躍中
              </p>
            </div>
            <div className="instructor-item5 sm-d-content-p-1">
              <img className="instructor-img" src={SONGMINJEONG} alt="image" />
            </div>
            <div className="instructor-item6 sm-d-content-p-l">
              <h2>SONG MIN JEONG</h2>
              <p>
                SS VOCAL academy(in ソウル)創立者<br />
                <br />
                JAZZやR&Bを学び、日本と韓国で本格的にKPOPアイドル育成を中心にレッスン経験多数<br />
                <br />
                <br />
                韓国事務所の練習生を指導し大手事務所からデビュー経験多数<br />
                <br />
                関わったオーディション番組<br />
                <br />
                関わったオーディション番組<br />
                girls planet999<br />
                プロデュース101<br />
                Who is Princess他<br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-content-p">
        <div className="big-content content-p">
          <h2 className="bold">ヴォイストレーナー</h2>
          <div className="grid-container w-100">
            <div className="instructor-item1 sm-d-content-p-r">
              <img className="instructor-img" src={KOTA} alt="image" />
            </div>
            <div className="instructor-item3 sm-d-content-p-r">
              <h2>KOTA</h2>
              <p>
                某芸能アーティストボーカル指導
                <br />
                <br />
                ボーカル&パフォーマー出演
                <br />
                EXILE 全国ツアーサポートメンバー
                <br />
                「EX SHOW2017京都公演」メインボーカル
                <br />
                映画『HIGH&LOW THE MOVIE』
                <br />
                映画『クローズEXPLODE』公開記念イベント
                <br />
                松竹芸能50周年興行イベント
                <br />
                『SKY OF JAPAN』 単独LIVE
                <br />
                <br />
                MV出演
                <br />
                Kis-My-Ft2
                <br />
                その他、TV、CM、イベント、映画等多数出演
              </p>
            </div>
            <div className="instructor-item2 sm-d-content-p-1">
              <img className="instructor-img" src={TSUBASA} alt="image" />
            </div>
            <div className="instructor-item4 sm-d-content-p-l">
              <h2>TSUBASA</h2>
              <p>
                ワンマンライブ出演
                <br />
                Re:Complex コンサートツアー
                <br />
                <br />
                ダンサー出演
                <br />
                EXILE / 三代目 J Soul Brothersツアー
                <br />
                『HIGH&LOW THE LIVE』
                <br />
                <br />
                イベント出演
                <br />
                KOBE COLLECTION 2018
                <br />
                超新星 ソンジェ発売記念イベント
                <br />
                オリコン Sound Blowin’2018~spring~
                <br />
                <br />
                その他、TV、CM、イベント、映画等多数出演
                <br />
              </p>
            </div>
            <div className="instructor-item6 sm-d-content-p-1">
              <img className="instructor-img" src={RENAN} alt="image" />
            </div>
            <div className="instructor-item6 sm-d-content-p-l">
              <h2>RENAN</h2>
              <p>
                1 Million dance studio 講師<br/>
                韓国練習生　指導
                <br/>
                <br/>
                ワンマンライブ出演
                <br />
                Re:Complex コンサートツアー
                <br />
                <br />
                ダンサー出演
                <br />
                EXILE / 三代目 J Soul Brothersツアー
                <br />
                『HIGH&LOW THE LIVE』
                <br />
                <br />
                イベント出演
                <br />
                KOBE COLLECTION 2018
                <br />
                超新星 ソンジェ発売記念イベント
                <br />
                オリコン Sound Blowin’2018~spring~
                <br />
                <br />
                その他、TV、CM、イベント、映画等多数出演
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="big-flex-grid-container">
        <div className="home-item1 content-p">
          <div className="center-div">
            <h3>憧れの舞台韓国で練習生に！</h3>
            <h3>
              特別育成コース終了後のレベルチェックで成績優秀者になると渡韓決定！
            </h3>
            <h3>
              ソウルでデビューに必要なスキルを学べる本格レッスンを受け、練習生、韓国デビューを目指そう！
            </h3>
            <h3>バックダンサー希望の場合、無料で韓国ダンス留学へ行けます。</h3>
          </div>
        </div>

        <div className="home-item2 m-content-p">
          <img className="w-100" src={TRANIEE} />
        </div>
      </div>
      <div className="content-p md-div mx-auto">

<div className="md-div">
  <h2>詳細</h2>
  <br />
  <p className="bold">
    K-POP STAR PROJECT<br />
    <br />
    募集期間<br />
    2022年3月22日〜2022年7月20日<br />
    <br />
    オンラインレッスン期間<br />
    2022年7月21日〜2023年1月12日<br />
    <br />
    オンラインオーディション<br />
    2023年1月末<br />
    <br />
    合格者発表<br/>
    {`2023年2月末  レッスン内容 レッスン動画配信 週１回 ６ヶ月間  計２４レッスン 
    （ダンス１２回/ヴォーカル１２回） 定期的にメンバー専用ページからレッスン動画が配信されるので、
    お好きな時間にそれを見て習得。質問、アドバイス等可能。   レベルチェック 受講終了後、歌、ダンスそれぞれ習った
    事の中から課題が出されるのでそれを動画に撮りオーディション事務局へ提出。講師により採点されます。 ※バックダンサー
    希望の場合は歌もありますが、レベルチェックではダンスの成績重視になります。   参加条件 K-POPアイドル、アーティスト、
    バックダンサー、ダンスアーティストになりたい 健康な１０歳以上の男女。国籍不問。 日本語が話せ、読み書きできる方。
       費用 ¥50,000(税込) (6ヶ月間のレッスン費込み)`}
    <br/>
    <br/>
    お支払い方法<br/>
    現金、クレジットカードより選択可能<br/>
    費用完全無料 当プロジェクトが全額負担<br/>
    </p>
    </div>
      <a className="nav-link" onClick={() => navigate(`/application`)}>
お申し込み
</a>
      </div>
      </div>
    </>
  );
}; 
  
export default Home;
