import ONEDAYINKOREA from "../../assets/img/One-day-in-Korea.jpg";
import ONEDANCESTUDIO from "../../assets/img/1MILLION-DANCE-STUDIO.jpg";
import AUDITIONPRACTICEGUIDANCE from "../../assets/img/Audition-practice-guidance.jpg";
import TRANIEECONTRACT from "../../assets/img/Trainee-contract.jpg";
import SHOPINGSTREET from "../../assets/img/shoping-street.jpg";
import KOREANAIR from "../../assets/img/korean-air.jpg";

import { useNavigate } from "react-router-dom";

const StudyAbroad = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            textAlign: "center",
            justifyContent: "center",
            backgroundImage:`URL('${SHOPINGSTREET}')`,
          }}
        >
        <div className="backdrop">
          <div
            style={{
              width: "250px",
              position: "relative",
              top: "40%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <h2>練習生留学</h2>
            <h2>韓国での生活</h2>
          </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage:
              `url(${KOREANAIR})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "fit-content",
          }}
        >
          <div className="backdrop">
          <div className="content-p m-w-350 d-w-480"
            style={{
              position: "relative",
              textAlign: "left",
            }}
          >
            <h2>K-POP練習生留学</h2>

            <div>
            <p className="bold">
            韓国で練習生を目指す留学です。<br/>
            ダンサーの場合、韓国で本格ダンス留学になります。<br/>
            ソウル市教育委員会 正式認可の留学機関を通すので安心！<br/>
            通常、数百万円程かかる費用を当プロジェクトが全額負担、さらに往復航空券もプレゼント！<br/>
            <br/>
            渡航時期<br/>
            コロナウイルス感染症の状況とご自身のご都合を考慮し、お好きな時期に渡航できます。<br/>
            <br/>
            渡航期間<br/>
            ●高卒以上の方<br/>
            コロナウイルス感染症の影響により皆様の安全を最優先とし、しばらくの間は日本にいながら韓国での同じレッスンを受けられるオンライン留学３ヶ月＋韓国留学３ヶ月(計６ヶ月)になります。ダンス留学の場合オンラインはなく、韓国３ヶ月です。<br/>
            ●小中高校に在学中の方<br/>
            学業優先となり学校が休みの春/夏/冬休みのいずれかにK-POP留学１ヶ月となります。<br/>
            <br/>
            含まれているもの<br/>
            日本からの往復渡航費、韓国での宿泊費、全レッスン費、空港送迎、現地２４時間サポート、通訳、練習生合格サポート、1MILLION DANCE STUDIO等お好きなダンススクールでのレッスン費
            <br/>
            ※現地での食費、交通費、海外旅行保険等は自己負担<br/>
            <br/>
            滞在先<br/>
            寮、シェアハウスまたはホームステイ<br/>
            <br/>
            この留学をすれば練習生になれる事を保証するものではなく、あくまで現地オーディションではご本人の実力勝負となります。<br/>
            最短で練習生として合格できるよう、最適な環境で最大のサポートを受けることができ、韓国で夢を叶えるビッグチャンスになります。

            </p>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div>
      <div className="text-center content-p">
      <h2>韓国大手芸能事務所へ</h2>
      <h2>あなたをご紹介</h2>
      <p>
        HYBE、JYP、SM、YG、STARSHIP、<br/>
        FNC、PLEDIS、CUBE、FANTAGIO、LOEN他<br/>
        ご希望の事務所へあなたを推薦！
      </p>
      </div>
      </div>

      <div className="big-flex-grid-container">
        <div className="application-item1 d-w-50">
          <img className="w-100 m-vh-45 d-vh-100" src={ONEDAYINKOREA} />
        </div>
        <div className="application-item2 d-w-50 bg-content-p-r bg-content-p-l">
          <div className="d-vh-100 m-vh-80 w-100">
          <div className="center-div w-content">
            <h2> 韓国での１日</h2>
            <h3>スケジュール例（練習生留学）</h3>
            <p className="bold">
              平日（月〜金）
              <br />
              9:00 韓国語レッスン
              <br />
              13:00 昼食
              <br />
              15:00 ダンスレッスン
              <br />
              17:00 ボイストレーニング
              <br />
              19:00 自主練習
              <br />
              21:00 帰宅
              <br />
              <br />
              土日 自主練習
              <br />
              または1 million dance studio等
              <br />
              お好きなダンススクールへ通いダンススキルの強化
              <br />
              <br />
              その他ヘアメイクレッスン、オーディション用写真撮影、美容指導、作詞作曲レッスン、韓国文化学習なども追加されます。
              <br />
              <br />
              ※ダンス留学の場合は曜日を問わずお好きなダンスレッスンを受けられます。
            </p>
          </div>
          </div>
        </div>
      </div>
      <div className="vh-100"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          textAlign: "center",
          justifyContent: "center",
          backgroundImage:
            `url(${ONEDANCESTUDIO})`,
        }}
      >
        <div className="backdrop">
        <div
          style={{
            maxWidth: "750px",
            position: "relative",
            top: "40%",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <h2>1MILLION DANCE STUDIOなど</h2>
          <h2>人気スタジオにも通える</h2>
          <p className="text-center">
          通常レッスンがない日に<br/>
          韓国人気No.１のK-POPダンススタジオ<br/>
          『1 ミリオンダンススタジオ』など<br/>
          お好きなダンススクール等にも自由に通うことができます。<br/>
          </p>
        </div>
        </div>
      </div>
      <div className="big-flex-grid-container d-vh-100">
        <div className="item1 d-w-50 content-p">
        <div className="center-div" style={{maxWidth: "720px"}}>

        <h2>オーディション実践指導</h2>
        <p>
        最短で夢を叶えられるよう一人一人にあったオーディション必勝法を伝授しあなたの魅力を最大限に引き出せるよう強力サポート！<br/>
        現地で非公開オーディションも参加できるので全ての大手芸能事務所へ所属できる可能性があります。
        </p>
        </div>
        </div>
        <div className="item2 ">
          <img style={{objectFit: "cover"}} className="w-100 m-vh-60 d-vh-100" src={AUDITIONPRACTICEGUIDANCE} />
        </div>
      </div>
      <div className="vh-60"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          textAlign: "center",
          justifyContent: "center",
          backgroundImage:
            `url(${TRANIEECONTRACT})`,
        }}
      >
        <div className="backdrop-pink">
        <div className="w-100 center-div">
          <h2>練習生 契約</h2>
          <div className="sm-container mx-auto">
          <p className="text-center bold">
            現地韓国でのオーディションに合格すると<br/>
            正式に練習生として韓国事務所と<br/>
            契約になります。<br/>
          </p>
          <p className="text-center bold">
          契約の際は通訳も同行し、さらに半年間<br/>
          何でも相談できる現地サポートがつきます。<br/>
          ここからは所属事務所のルールに従い<br/>
          練習生としてデビューを目指して<br/>
          頑張りましょう！<br/>
          </p>
          <div
          className="mx-auto"
          style={{
            cursor: "pointer",
            backgroundColor: "white",
            color: "black",
            fontSize: "1.5rem",
            width: "190px",
            height: "50px",
            borderRadius: "5px",
          }}
        >
          <div className="center-div w-content" onClick={() => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              navigate('/お申し込み');
            }
            }>
            お申し込み
          </div>
        </div>
          </div>
        </div>
        </div>
      </div>
      <div className="vh-30">

      </div>
    </div>
  );
};

export default StudyAbroad;
